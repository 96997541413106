import React from "react";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import { graphql, Link } from "gatsby";
import FilmCard from "../components/filmCard";
import { IndexQueryProps } from "../types";
import Grid from "@material-ui/core/Grid";
import ArticleCard from "../components/articleCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  horizontalWrapper: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      width: 920
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      overflowX: "scroll"
    }
  },
  headings: {
    [theme.breakpoints.up("md")]: {
      marginTop: 13
    }
  }
}));

const IndexPage: React.FC<IndexQueryProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Home" />
      <Grid container spacing={3} justifyContent="center">
        <Grid item sm={2} xs={3}>
          <div style={{ maxWidth: `135px`, marginBottom: `1.45rem` }}>
            <Image />
          </div>
        </Grid>
        <Grid item sm={8} xs={7}>
          <div className={classes.headings}>
          <Typography variant="h4" component="h1">
            LenoJeno's Film Website
          </Typography>
          <Typography variant="h6">
            Reviews, lists and articles on your favourite films.
          </Typography>
          <MuiLink href="https://letterboxd.com/lenojeno/">letterboxd</MuiLink>
          <MuiLink
            style={{ marginLeft: 20 }}
            href="https://www.imdb.com/user/ur119526840/"
          >
            imdb
          </MuiLink>
          <MuiLink
            style={{ marginLeft: 20 }}
            href="https://twitter.com/leno__jeno/"
          >
            twitter
          </MuiLink>
          </div>
        </Grid>
        
      </Grid>
      <Divider />
      {data ? (
        <div>
          <div style={{ display: "flex" }}>
            <Typography style={{ flexGrow: 1 }} variant="h6">
              Latest Reviews:
            </Typography>
            <Typography variant="h6" color="textSecondary">
              <Link state={{ navigatedTo: true }} to="/reviews">
                more
              </Link>
            </Typography>
          </div>
          <div className={classes.horizontalWrapper}>
            {data.films.edges.map((film: any) => (
              <FilmCard
                key={film.node.frontmatter.path}
                size="l"
                film={film.node}
              />
            ))}
          </div>
          <div style={{ height: 20 }} />
          <div style={{ display: "flex" }}>
            <Typography style={{ flexGrow: 1 }} variant="h6">
              Latest Articles:
            </Typography>
            <Typography variant="h6" color="textSecondary">
              <Link state={{ navigatedTo: true }} to="/articles">
                more
              </Link>
            </Typography>
          </div>

          <Grid container>
            {data.articles.edges.map((article: any) => (
              <Grid
                item
                xs={12}
                style={{ marginBottom: 5 }}
                key={article.node.frontmatter.path}
              >
                <ArticleCard
                  article={article.node}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    films: allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/films/.*\\.md$/" } }, sort: { fields:frontmatter___date, order: DESC }, limit:5) {
      edges {
        node {
          frontmatter {
            path
            title
            date
            poster {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    articles: allMarkdownRemark(filter: { fileAbsolutePath:{regex:"/articles/.*\\.md$/" } }, sort: { fields:frontmatter___date, order: DESC }, limit:5) {
      edges {
        node {
          excerpt(pruneLength: 250)
          frontmatter {
            path
            date
            author
            title
          }
        }
      }
    }
  }
`;

export default IndexPage;
