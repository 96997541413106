import React from "react";
import { navigate } from "gatsby";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Review } from "../types";
import Img from "gatsby-image";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 5
    }
  }
}));

type Props = { film: Review; size: "l" | "m" };
const FilmCard: React.FC<Props> = ({ film, size }) => {
  const classes = useStyles();
  return (
    <Tooltip title={film.frontmatter.title}>
      <Card
        className={classes.card}
        style={{
          width: size === "l" ? 180 : 170,
          minWidth: size === "l" ? 180 : 170
        }}
      >
        <CardActionArea
          style={{ height: size === "l" ? 270 : 255 }}
          onClick={() =>
            navigate(film.frontmatter.path, { state: { navigatedTo: true } })
          }
        >
          <Img
            style={{
              width: size === "l" ? 180 : 170,
              height: size === "l" ? 270 : 255
            }}
            fluid={film.frontmatter.poster.childImageSharp.fluid}
          />
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default FilmCard;
