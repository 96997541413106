import React from "react";
import { navigate } from "gatsby";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Article } from "../types";
import { grey } from "@material-ui/core/colors"

type Props = { article: Article };
const ArticleCard: React.FC<Props> = ({ article }) => {
  return (
    <Card style={{ marginLeft: 2, marginRight: 2, borderRadius: 0, border: `${grey[300]} solid 1px` }} elevation={0}>
      <CardActionArea
        disableRipple
        onClick={() =>
          navigate(article.frontmatter.path, { state: { navigatedTo: true } })
        }
      >
        <CardContent>
          <Typography variant="h5">{article.frontmatter.title}</Typography>
          <Typography variant="body2">{article.excerpt}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ArticleCard;
